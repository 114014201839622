// ** Theme Type Import
import { Theme } from '@mui/material/styles'

const Typography = (theme: Theme) => {
    return {
        h1: {
            fontWeight: 500,
            letterSpacing: '-1.5px',
            color: theme.palette.text.primary
        },
        h2: {
            fontWeight: 500,
            letterSpacing: '-0.5px',
            color: theme.palette.text.primary
        },
        h3: {
            fontWeight: 500,
            letterSpacing: 0,
            color: theme.palette.text.primary
        },
        h4: {
            fontWeight: 500,
            letterSpacing: '0.25px',
            color: theme.palette.text.primary
        },
        h5: {
            fontWeight: 500,
            letterSpacing: 0,
            color: theme.palette.text.primary
        },
        h6: {
            letterSpacing: '0.15px',
            color: theme.palette.text.primary
        },
        subtitle1: {
            letterSpacing: '0.15px',
            color: theme.palette.text.primary
        },
        subtitle2: {
            letterSpacing: '0.1px',
            color: theme.palette.text.secondary
        },
        body1: {
            fontSize: '0.875rem',
            letterSpacing: '0.15px',
            color: theme.palette.text.primary
        },
        body2: {
            lineHeight: 1.429,
            fontSize: '0.8125rem',
            letterSpacing: '0.15px',
            color: theme.palette.text.primary
        },
        button: {
            letterSpacing: '0.4px',
            color: theme.palette.text.primary
        },
        caption: {
            lineHeight: 1.25,
            letterSpacing: '0.4px',
            color: theme.palette.text.secondary
        },
        overline: {
            letterSpacing: '1px',
            color: theme.palette.text.secondary
        },
        newBodySm: {
            fontSize: '0.875rem',
            fontWeight: '400',
            lineHeight: '1.5rem',
            letterSpacing: '0rem'
        },
        newBodyMd: {
            fontSize: '1rem',
            fontWeight: '400',
            lineHeight: '1.75rem',
            letterSpacing: '0rem'
        },
        newBodyLg: {
            fontSize: '1.25rem',
            fontWeight: '400',
            lineHeight: '2rem',
            letterSpacing: '0rem'
        },
        newLabelSm: {
            fontSize: '0.75rem',
            fontWeight: '500',
            lineHeight: '1rem',
            letterSpacing: '0rem'
        },
        newLabelMd: {
            fontSize: '0.875rem',
            fontWeight: '600',
            lineHeight: '1rem',
            letterSpacing: '0rem'
        },
        newLabelLg: {
            fontSize: '1rem',
            fontWeight: '600',
            lineHeight: '1.25rem',
            letterSpacing: '0rem'
        },
        newTitleSm: {
            fontSize: '1rem',
            fontWeight: '500',
            lineHeight: '1.25rem',
            letterSpacing: '0rem'
        },
        newTitleMd: {
            fontSize: '1.25rem',
            fontWeight: '400',
            lineHeight: '1.75rem',
            letterSpacing: '0rem'
        },
        newTitleLg: {
            fontSize: '1.75rem',
            fontWeight: '500',
            lineHeight: '2.5rem',
            letterSpacing: '0rem'
        },
        newHeadlineLg: {
            fontSize: '2rem',
            fontWeight: '400',
            lineHeight: '2.5rem',
            letterSpacing: '-0.125rem'
        },
        newDisplayMd: {
            fontSize: '3rem',
            fontWeight: '400',
            lineHeight: '3.5rem',
            letterSpacing: '-0.125rem'
        },
        newDisplayLg: {
            fontSize: '4.75rem',
            fontWeight: '400',
            lineHeight: '5.75rem',
            letterSpacing: '-0.25rem'
        }
    }
}

export default Typography
