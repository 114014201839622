import MuiAvatar, { type AvatarProps } from '@mui/material/Avatar'
import useColor from 'src/hooks/useColor'

type Size = number | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'
export type Props = AvatarProps & {
    size?: Size
    color?: string
}
export default function Avatar({
    variant = 'circular',
    size = 'md',
    color = 'newPrimary',
    children,
    sx,
    ...rest
}: Props) {
    const parsedColor = useColor(color)
    const getSize = () => {
        switch (size) {
            case 'xs':
                return 15
            case 'sm':
                return 30
            case 'md':
                return 45
            case 'lg':
                return 60
            case 'xl':
                return 75
            case '2xl':
                return 90
            case '3xl':
                return 105
            default:
                return size
        }
    }
    const avatarSize = getSize()

    return (
        <MuiAvatar
            variant={variant}
            sx={{
                width: avatarSize,
                height: avatarSize,
                bgcolor: parsedColor,
                ...sx
            }}
            {...rest}
        >
            {children}
        </MuiAvatar>
    )
}
