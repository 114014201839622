import Box, { type BoxProps } from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

type Props = BoxProps & {
    title?: string
}

const g2Srcs = [
    {
        name: 'g2-2024-summer-best-result',
        src: '/images/new-theme/g2/g2-2024-summer-best-result.png'
    },
    {
        name: 'g2-2024-summer-momentum-leader',
        src: '/images/new-theme/g2/g2-2024-summer-momentum-leader.png'
    },
    {
        name: 'g2-2024-summer-easiest-to-use',
        src: '/images/new-theme/g2/g2-2024-summer-easiest-to-use.png'
    }
]

export default function G2Score({ title = 'People Trusted Us', ...rest }: Props) {
    return (
        <Box display='flex' flexDirection='column' alignItems='center' {...rest}>
            <Typography component='h4' variant='newDisplayMd' color='white' align='center'>
                {title}
            </Typography>
            <Box mt={8} sx={{ '& img': { display: 'block', width: 250, mx: 'auto' } }}>
                <img src='/images/new-theme/others/rating-light.png' />
            </Box>
            <Stack mt={10} direction='row' alignItems='center' gap={4}>
                {g2Srcs.map((g2) => (
                    <img key={g2.name} src={g2.src} alt={g2.name} style={{ width: '100px' }} />
                ))}
            </Stack>
        </Box>
    )
}
