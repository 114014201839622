import { useState, useRef, useEffect } from 'react'
import Box, { type BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { SwiperSlide } from 'swiper/react'
import { Swiper as SwiperType } from 'swiper/types'
import Carousel from 'src/components/shared/Carousel'
import G2ScoreSlide from 'src/components/review/G2Score'
import SalesBoostSlide from 'src/components/review/SalesBoost'
import ReviewCardSlide from 'src/components/review/Card'

type Props = BoxProps & {
    autoPlay?: boolean
    autoPlayCount?: number
    slideChangeSpeed?: number
    autoPlayInterval?: number
}

export default function ReviewsSlider({
    autoPlay = true,
    autoPlayCount = 1,
    slideChangeSpeed = 500, //speed of slide transition
    autoPlayInterval = 4000, //interval of slide transition in autoplay mode
    ...rest
}: Props) {
    const swiperRef = useRef<SwiperType>(null!)
    const [count, setCount] = useState(0) //how many times we finish sliding through all slides
    const onReachBeginning = () => {
        setCount((old) => old + 1)
    }
    useEffect(() => {
        if (count >= autoPlayCount) {
            const swiper = swiperRef.current
            if (swiper) {
                swiper.autoplay.stop()
            }
        }
    }, [count])

    return (
        <Box {...rest}>
            <Carousel
                ref={swiperRef}
                loop={false} //onReachBeginning event will not fire with loop:true
                rewind
                slidesPerView={1}
                spaceBetween={0}
                pagination={{
                    clickable: true
                }}
                speed={slideChangeSpeed}
                autoplay={
                    autoPlay
                        ? {
                              delay: autoPlayInterval,
                              disableOnInteraction: false,
                              pauseOnMouseEnter: false,
                              stopOnLastSlide: false,
                              reverseDirection: false,
                              waitForTransition: true
                          }
                        : undefined
                }
                onReachBeginning={onReachBeginning}
                className='carousel-small-white-pagination'
            >
                <SwiperSlide>
                    <G2ScoreSlide />
                </SwiperSlide>
                <SwiperSlide>
                    <SalesBoostSlide />
                </SwiperSlide>
                <SwiperSlide>
                    <Typography variant='newDisplayMd' color='#fff' align='center'>
                        What Our Customers Say
                    </Typography>
                    <ReviewCardSlide
                        userName='Jane Blunch'
                        userDescription='Head of Demand Generation at Ashby'
                        userImgSrc='/images/new-theme/avatars/avatar-22.png'
                        content='Why not CUF? For me, it’s clear that it’s much easier to have a tool like Apollo that does both the lead generation and the email and phone automation rather than have it separately.'
                        helperText='24 M'
                        helperDescriptions='Decrease in Time to Meeting'
                        helperColor='newPastelGold.main'
                        color='newPastelGold.light4'
                        sx={{ mt: 8 }}
                    />
                </SwiperSlide>
            </Carousel>
        </Box>
    )
}
