import { SwiperSlide } from 'swiper/react'
import Box, { type BoxProps } from '@mui/material/Box'
import Carousel from 'src/components/shared/Carousel'
import BasicCard, { type Info } from 'src/components/b2b/BasicCard'

export type Item = Info
type Props = BoxProps & {
    autoPlay?: boolean
    reverse?: boolean
    items: Item[]
    slideWidth?: number
    duration?: number
}

export default function BasicCardsSlider({
    autoPlay = false,
    reverse = false,
    items = [],
    slideWidth = 185, //in 'px' unit
    duration = 10 * 1000,
    ...rest
}: Props) {
    return (
        <Box {...rest}>
            <Carousel
                loop
                rewind={false}
                slidesPerView={'auto'}
                spaceBetween={10}
                draggable={false}
                centeredSlides={false}
                speed={autoPlay ? duration : undefined}
                autoplay={
                    autoPlay
                        ? {
                              delay: 0,
                              stopOnLastSlide: false,
                              pauseOnMouseEnter: false,
                              reverseDirection: reverse,
                              disableOnInteraction: false,
                              waitForTransition: true
                          }
                        : undefined
                }
                wrapperClass={`${autoPlay ? 'pointer-events-none ease-linear' : ''}`}
            >
                {items.map((item) => (
                    <SwiperSlide key={item.title} style={{ width: `${slideWidth}px` }}>
                        <BasicCard {...item} />
                    </SwiperSlide>
                ))}
            </Carousel>
        </Box>
    )
}
