import Avatar from 'src/components/shared/Avatar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card, { type CardProps } from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

type Props = CardProps & {
    userName: string
    userDescription?: string
    userImgSrc?: string
    content: string
    helperText?: string
    helperDescriptions?: string
    color?: string
    helperColor?: string
}

export default function ReviewCard({
    userName,
    userDescription,
    userImgSrc,
    content,
    helperText,
    helperDescriptions,
    color = 'transparent',
    helperColor = 'black',
    sx,
    ...rest
}: Props) {
    return (
        <Card sx={{ bgcolor: color, p: 2, borderRadius: 3, ...sx }} {...rest}>
            <CardContent>
                <Box display='flex' flexDirection='row' alignItems='center' gap={2}>
                    <Avatar src={userImgSrc} alt={userName} size={68} color='primary' />
                    <div>
                        <Typography component='p' variant='newTitleMd' color='newNeutral.main'>
                            {userName}
                        </Typography>
                        {!!userDescription && (
                            <Typography component='p' variant='newBodySm' marginTop={2} color='newNeutral.main'>
                                {userDescription}
                            </Typography>
                        )}
                    </div>
                </Box>
            </CardContent>
            <CardContent>
                <Typography component='p' variant='newBodyMd' color='newNeutral.main'>
                    {content}
                </Typography>
            </CardContent>
            {!!helperText && (
                <CardContent sx={{ display: 'block', color: helperColor }}>
                    <Typography component='p' variant='newDisplayMd'>
                        {helperText}
                    </Typography>
                    {!!helperDescriptions && (
                        <Typography component='p' variant='newBodyMd' ml={'0px !important'}>
                            {helperDescriptions}
                        </Typography>
                    )}
                </CardContent>
            )}
        </Card>
    )
}
