import WebGrid, { type Props as WebGridProps } from 'src/components/shared/WebGrid'
import ReviewsSlider from 'src/components/review/Slider'
import CompaniesSlider from 'src/components/b2b/CompaniesSlider'

type Props = Omit<WebGridProps, 'type'>

export default function AuthSidebar({ ...rest }: Props) {
    return (
        <WebGrid
            type='neutral-light'
            lineSize={1}
            cellSize='sm'
            topFade
            bottomFade
            px={0}
            py={10}
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            alignItems='center'
            gap={4}
            {...rest}
        >
            <img src='/images/new-theme/logos/white-text-h.png' alt='CUFinder' style={{ width: '125px' }} />
            <ReviewsSlider width={1} px={8} />
            <CompaniesSlider width={1} />
        </WebGrid>
    )
}
