import { Icon as Iconify, type IconProps as IconifyProps } from '@iconify/react'
import useColor from 'src/hooks/useColor'

type Size = number | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl'
type Display = 'inline' | 'block' | 'inline-block' | 'flex' | 'inline-flex' | 'grid' | 'inline-grid'
export type Props = IconifyProps & {
    display?: Display
    size?: Size
}

export default function Icon({
    display = 'inline-block',
    icon,
    color = 'newPrimary',
    size = 'md',
    className = '',
    style,
    ...rest
}: Props) {
    const parsedColor = useColor(color)
    const getSize = () => {
        switch (size) {
            case 'xs':
                return 12
            case 'sm':
                return 16
            case 'md':
                return 20
            case 'lg':
                return 25
            case 'xl':
                return 32
            case '2xl':
                return 45
            case '3xl':
                return 60
            case '4xl':
                return 75
            case '5xl':
                return 100
            default:
                return size
        }
    }
    const iconSize = getSize()

    return (
        <Iconify
            icon={icon}
            color={parsedColor}
            fontSize={iconSize}
            className={className}
            style={{
                display,
                ...style
            }}
            {...rest}
        />
    )
}

//? use display:'block' for times height is wrong
