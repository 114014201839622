// ** react router imports
import { Navigate, createBrowserRouter } from 'react-router-dom'

// ** routing files
import authRoutes from './auth.routes'
import panelRoutes from './panel.routes'
import errorRoutes from './error.routes'

// ** layouts imports
import RootLayout from 'src/@core/layouts/RootLayout'
// import AuthLayout from 'src/@core/layouts/AuthLayout'
import AuthDefaultLayout from 'src/layouts/auth/Default'
import UserLayout from 'src/layouts/UserLayout'
import BlankLayout from 'src/@core/layouts/BlankLayout'

// ** error pages
import AppCrash from 'src/pages/errors/app-crash'

const mainRoutes = createBrowserRouter([
    {
        id: 'root',
        path: '/',
        element: <RootLayout />,
        errorElement: <AppCrash />,
        children: [
            {
                id: 'authentication-routes-parent',
                path: 'auth',
                element: <AuthDefaultLayout />,
                children: authRoutes
            },
            // {
            //     id: 'authentication-test-routes-parent',
            //     path: 'auth/test',
            //     element: <AuthDefaultLayout />,
            //     children: authTestRoutes
            // },
            {
                id: 'panel-routes-parent',
                path: '/',
                element: <UserLayout />,
                children: panelRoutes
            },
            {
                id: 'error-routes-parent',
                path: 'error',
                element: <BlankLayout />,
                children: errorRoutes
            },
            {
                id: 'not-found-screen',
                path: '*',
                element: <Navigate to='/error/404' />
            }
        ]
    }
])

export default mainRoutes
