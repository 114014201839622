import Box, { type BoxProps } from '@mui/material/Box'
import useTheme from '@mui/material/styles/useTheme'

type Type = 'neutral-light'
type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
export type Props = BoxProps & {
    type: Type //for coloring
    lineSize?: number //length of grid lines
    cellSize?: Size | number //each rect cell size
    topFade?: boolean
    bottomFade?: boolean
    leftFade?: boolean
    rightFade?: boolean
    centerFade?: boolean
}

export default function WebGrid({
    type,
    lineSize = 1,
    cellSize = 'md',
    topFade = false,
    bottomFade = false,
    leftFade = false,
    rightFade = false,
    centerFade = false,
    children,
    sx,
    ...rest
}: Props) {
    const theme = useTheme()
    let bgColor = 'transparent'
    let gridColor = 'transparent'
    let rectSize = 0
    const getColors = () => {
        switch (type) {
            case 'neutral-light':
                bgColor = theme.palette.newNeutral.main
                gridColor = 'rgba(255,255,255,.16)'
                break
            default:
                bgColor = 'transparent'
                gridColor = 'transparent'
        }
    }
    const getCellSize = () => {
        switch (cellSize) {
            case 'xs':
                rectSize = 20
                return
            case 'sm':
                rectSize = 30
                return
            case 'md':
                rectSize = 45
                return
            case 'lg':
                rectSize = 60
                return
            case 'xl':
                rectSize = 75
                return
            default:
                rectSize = 45
        }
    }
    getColors()
    getCellSize()

    return (
        <Box
            sx={{
                overflow: 'hidden',
                position: 'relative',
                zIndex: 1,
                bgcolor: bgColor,
                backgroundImage: `linear-gradient(to right, ${gridColor} ${lineSize}px, transparent ${lineSize}px), linear-gradient(to bottom, ${gridColor} ${lineSize}px, transparent ${lineSize}px)`,
                backgroundSize: `${rectSize}px ${rectSize}px, ${rectSize}px ${rectSize}px`,
                ...sx
            }}
            {...rest}
        >
            {children}
            {topFade && (
                <Box
                    width='100%'
                    height='100%'
                    position='absolute'
                    left={0}
                    top={0}
                    zIndex={-1}
                    sx={{
                        pointerEvents: 'none',
                        backgroundImage: `linear-gradient(to bottom,${bgColor} 0%, transparent 50%)`
                    }}
                />
            )}
            {bottomFade && (
                <Box
                    width='100%'
                    height='100%'
                    position='absolute'
                    left={0}
                    bottom={0}
                    zIndex={-1}
                    sx={{
                        pointerEvents: 'none',
                        backgroundImage: `linear-gradient(to top,${bgColor} 0%, transparent 50%)`
                    }}
                />
            )}
            {leftFade && (
                <Box
                    width='100%'
                    height='100%'
                    position='absolute'
                    left={0}
                    top={0}
                    zIndex={-1}
                    sx={{
                        pointerEvents: 'none',
                        backgroundImage: `linear-gradient(to right,${bgColor} 0%, transparent 50%)`
                    }}
                />
            )}
            {rightFade && (
                <Box
                    width='100%'
                    height='100%'
                    position='absolute'
                    right={0}
                    top={0}
                    zIndex={-1}
                    sx={{
                        pointerEvents: 'none',
                        backgroundImage: `linear-gradient(to left,${bgColor} 0%, transparent 50%)`
                    }}
                />
            )}
            {centerFade && (
                <Box
                    width='100%'
                    height='100%'
                    position='absolute'
                    left={0}
                    top={0}
                    zIndex={-1}
                    sx={{
                        pointerEvents: 'none',
                        backgroundImage: `linear-gradient(to bottom,transparent 0%, ${bgColor} 50%, transparent 100%)`
                    }}
                />
            )}
        </Box>
    )
}
