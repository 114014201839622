import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import WebGrid, { type Props as WebGridProps } from 'src/components/shared/WebGrid'
import BasicCardsSlider, { type Item } from 'src/components/b2b/BasicCardsSlider'
import CompaniesSlider from 'src/components/b2b/CompaniesSlider'

type Props = Omit<WebGridProps, 'type'>

const countries: Item[] = [
    {
        title: 'USA',
        icon: 'circle-flags:us-um'
    },
    {
        title: 'china',
        icon: 'circle-flags:cn'
    },
    {
        title: 'india',
        icon: 'circle-flags:in'
    },
    {
        title: 'germany',
        icon: 'circle-flags:de'
    },
    {
        title: 'russia',
        icon: 'circle-flags:ru'
    },
    {
        title: 'france',
        icon: 'circle-flags:fr'
    },
    {
        title: 'spain',
        icon: 'circle-flags:es'
    },
    {
        title: 'UK',
        icon: 'circle-flags:uk'
    }
]
const industries: Item[] = [
    {
        title: 'restaurant',
        icon: 'solar:chef-hat-bold',
        color: 'newSuccess.main'
    },
    {
        title: 'clothing store',
        icon: 'solar:hanger-2-bold',
        color: 'newPastelPurple.main'
    },
    {
        title: 'agriculture',
        icon: 'solar:leaf-bold',
        color: 'newPastelGold.main'
    },
    {
        title: 'beauty salon',
        icon: 'solar:cosmetic-bold',
        color: 'newPastelRed.main'
    },
    {
        title: 'pet shop',
        icon: 'solar:paw-bold',
        color: 'newWarning.main'
    },
    {
        title: 'store',
        icon: 'solar:shop-bold',
        color: 'newPastelCyan.main'
    },
    {
        title: 'cafe',
        icon: 'solar:tea-cup-bold',
        color: 'newInfo.main'
    },
    {
        title: 'furniture',
        icon: 'solar:armchair-2-bold',
        color: 'newPastelGreen.main'
    }
]

export default function AuthSidebar({ ...rest }: Props) {
    return (
        <WebGrid
            type='neutral-light'
            lineSize={1}
            cellSize='sm'
            topFade
            bottomFade
            px={0}
            py={10}
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            alignItems='center'
            gap={4}
            {...rest}
        >
            <img src='/images/new-theme/logos/b2b-white-h.png' alt='CUFinder' style={{ width: '150px' }} />
            <Box width={1}>
                <Typography component='h2' variant='newDisplayMd' align='center' color='white' width={0.95} mx='auto'>
                    The Largest B2B Database With{' '}
                    <Typography component='span' variant='newDisplayMd' color='newPastelBlue.light4'>
                        +250M
                    </Typography>{' '}
                    Business In{' '}
                    <Typography component='span' variant='newDisplayMd' color='newPastelBlue.light4'>
                        221
                    </Typography>{' '}
                    Countries
                </Typography>
                <Box mt={12}>
                    <BasicCardsSlider
                        items={countries}
                        autoPlay={true}
                        reverse={false}
                        slideWidth={185}
                        duration={countries.length * 2 * 1000}
                    />
                    <BasicCardsSlider
                        items={industries}
                        autoPlay={true}
                        reverse={true}
                        slideWidth={185}
                        duration={industries.length * 2 * 1000}
                        mt={4}
                    />
                </Box>
            </Box>
            <CompaniesSlider width={1} />
        </WebGrid>
    )
}
