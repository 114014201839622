import Box, { type BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'

type Props = BoxProps & {
    title?: string
}

export default function SalesBoost({ title = 'Be Your the Next', ...rest }: Props) {
    return (
        <Box display='flex' flexDirection='column' alignItems='center' {...rest}>
            <Typography component='h4' variant='newDisplayMd' color='white' align='center' mb={8}>
                {title}
            </Typography>
            <img
                src='/images/new-theme/others/boost-chart-light.png'
                alt='boost-chart-light'
                style={{ width: '260px' }}
            />
            <Typography component='p' variant='newBodyMd' color='white' align='center' mt={5} width={275}>
                <Typography component='span' variant='newBodyMd' color='newWarning.main'>
                    92.3%
                </Typography>{' '}
                of Businesses See a{' '}
                <Typography component='span' variant='newBodyMd' color='newWarning.main'>
                    46.9%
                </Typography>{' '}
                Sales Boost with CUFinder
            </Typography>
        </Box>
    )
}
