import Box, { type BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Skeleton from '@mui/material/Skeleton'
import Icon from 'src/components/shared/Icon'

export type Info = {
    title: string
    icon: string
    color?: string
    skeleton?: boolean
}
type Props = BoxProps & Info

export default function BasicCard({ title, icon, color = 'newPrimary.main', skeleton = true, ...rest }: Props) {
    return (
        <Box bgcolor='white' borderRadius={1.4} p={3} overflow='hidden' {...rest}>
            <Stack direction='row' alignItems='center' spacing={2}>
                <Icon icon={icon} color={color} size={36} className='shrink-0' />
                <Box className='grow-1'>
                    <Typography
                        component='p'
                        variant='newBodyMd'
                        fontWeight={500}
                        color='newNeutral.main'
                        textTransform='capitalize'
                    >
                        {title}
                    </Typography>
                    <Stack direction='row' spacing={1} mt={1}>
                        <Skeleton
                            variant='text'
                            width={55}
                            height={7.5}
                            animation={false}
                            sx={{ bgcolor: '#f1f1f1', transform: 'scale(1)' }}
                        />
                        <Skeleton
                            variant='text'
                            width={55}
                            height={7.5}
                            animation={false}
                            sx={{ bgcolor: '#f1f1f1', transform: 'scale(1)' }}
                        />
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}
