import { forwardRef, type ReactNode, type MutableRefObject, type ForwardedRef, type ComponentProps } from 'react'
import Box from '@mui/material/Box'
import { Swiper } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import { type Swiper as SwiperType } from 'swiper/types'
import { type SxProps } from '@mui/system'
import 'swiper/css'

type SwiperProps = Omit<ComponentProps<typeof Swiper>, 'children' | 'className'>
export type Props = SwiperProps & {
    children?: ReactNode
    sx?: SxProps
    className?: string
}

const Carousel = ({ children, sx, className = '', ...rest }: Props, ref?: ForwardedRef<SwiperType>) => {
    const onInit = (swiper: SwiperType) => {
        if (swiper && ref) (ref as MutableRefObject<SwiperType>).current = swiper
    }

    return (
        <Box sx={{ ...sx }} className={`${className}`}>
            <Swiper onInit={onInit} modules={[Autoplay, Navigation, Pagination]} {...rest}>
                {children}
            </Swiper>
        </Box>
    )
}

export default forwardRef(Carousel)
