import { useState, useEffect, useCallback } from 'react'
import useTheme from '@mui/material/styles/useTheme'
import type { Palette } from '@mui/material/styles'

const useColor = (inputColor: string, separator = '.') => {
    //inputColor  can be something like primary,primary.main,primary.dark,primary.light3,red,#0f0,...
    const theme = useTheme()
    const findColor = useCallback(() => {
        const splitColor = inputColor.split(separator)
        const colorName = splitColor[0] as keyof Palette
        const colorVariant = (splitColor[1] || 'main') as unknown
        // @ts-ignore
        const result = theme.palette[colorName]?.[colorVariant] || inputColor
        return result
    }, [inputColor, separator])
    const [color, setColor] = useState(() => {
        return findColor()
    })
    useEffect(() => {
        setColor(findColor())
    }, [findColor])
    return color
}

export default useColor
